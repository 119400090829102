import { API, authClient } from "../index";

export const postCountry = (country) => {
	return authClient().post(`${API}/country`, country);
};

export const getCountries = () => {
	return authClient().get(`${API}/country`);
};

export const getCountryById = (id) => {
	return authClient().get(`${API}/country/${id}`);
};

export const updateCountry = (id, country) => {
	return authClient().put(`${API}/country/${id}`, country);
};

export const deleteCountry = (id) => {
	return authClient().delete(`${API}/country/${id}`);
};
