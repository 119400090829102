import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import replaceSpecialCharacters from "replace-special-characters";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getLogs } from "../../../../api/log";
import { alertError } from "../../../../utils/logger";
import Table, { dateFormatter } from "../../../components/tables/table";

function getData(logs) {
	let data = [];

	for (let i = 0; i < logs.length; ++i) {
		const elem = {};
		const log = logs[i];

		elem.user = log?.user?.fullName || log?.userName;
		elem.userIP = log?.userIP;
		elem.action = log?.action;
		elem.field = log?.field;
		elem.createdAt = log?.createdAt;
		elem.id = log?._id;

		data = data.concat(elem);
	}
	return data;
}

export default function LogsPage() {
	const [data, setData] = useState([]);

	const location = useLocation();

	const history = useHistory();

	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const columns = [
		{ dataField: "user", text: "Usuario", sort: true },
		{ dataField: "userIP", text: "IP", sort: true },
		{ dataField: "field", text: "Campo", sort: true },
		{ dataField: "action", text: "Acción", sort: true },
		{
			dataField: "createdAt",
			text: "Date",
			formatter: dateFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getLogs()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get logs.",
				});
			});
	}, []);

	function exportToCsv(filename, rows) {
		let csvFile = "";
		csvFile += `${["USER", "IP", "FIELD", "ACTION", "DATE"].join(";")}\r\n`;

		rows.forEach((row) => {
			const properValues = [
				replaceSpecialCharacters(row?.user),
				replaceSpecialCharacters(row?.userIP),
				replaceSpecialCharacters(row?.field),
				replaceSpecialCharacters(row?.action),
				replaceSpecialCharacters(row?.createdAt),
			];
			return (csvFile += `${properValues.join(";")}\r\n`);
		});

		const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(blob, filename);
		} else {
			const link = document.createElement("a");
			if (link.download !== undefined) {
				// feature detection
				// Browsers that support HTML5 download attribute
				const url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", filename);
				link.style.visibility = "hidden";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	return (
		<>
			<Card>
				<CardHeader title="Log list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-secondary"
							onClick={() => {
								let today = new Date();
								const dd = String(today.getDate()).padStart(
									2,
									"0"
								);

								const mm = String(
									today.getMonth() + 1
								).padStart(2, "0");

								const yyyy = today.getFullYear();

								today = dd + "-" + mm + "-" + yyyy;

								exportToCsv("Logs_" + today, data);
							}}
						>
							Export excel
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
