import { uploadFilesGetLinks } from "../files";
import { API, authClient } from "../index";

export const postSupplier = async (supplier, images, files) => {
	if (images && images?.length) {
		let imagesURLs = await uploadFilesGetLinks(images, "suppliers");
		supplier.imagesURLs = (supplier.imagesURLs || []).concat(imagesURLs);
	}
	if (files && files?.length) {
		let filesURLs = await uploadFilesGetLinks(files, "suppliers");
		supplier.filesURLs = (supplier.filesURLs || []).concat(filesURLs);
	}
	for (const artworkDesire of supplier?.artworkDesires) {
		if (artworkDesire.images && artworkDesire.images?.length) {
			let imagesURLs = await uploadFilesGetLinks(
				artworkDesire.images,
				"artworkDesires"
			);
			artworkDesire.imagesURLs = imagesURLs;
		}
		if (artworkDesire.files && artworkDesire.files?.length) {
			let filesURLs = await uploadFilesGetLinks(
				artworkDesire.files,
				"artworkDesires"
			);
			artworkDesire.filesURLs = filesURLs;
		}
	}
	return authClient().post(`${API}/supplier`, supplier);
};

export const getSuppliers = () => {
	return authClient().get(`${API}/supplier`);
};

export const getSupplierById = (id) => {
	return authClient().get(`${API}/supplier/${id}`);
};

export const updateSupplier = async (id, supplier, images, files) => {
	if (images && images?.length) {
		let imagesURLs = await uploadFilesGetLinks(images, "suppliers");
		supplier.imagesURLs = (supplier.imagesURLs || []).concat(imagesURLs);
	}
	if (files && files?.length) {
		let filesURLs = await uploadFilesGetLinks(files, "suppliers");
		supplier.filesURLs = (supplier.filesURLs || []).concat(filesURLs);
	}
	for (const artworkDesire of supplier?.artworkDesires) {
		if (artworkDesire.images && artworkDesire.images?.length) {
			let imagesURLs = await uploadFilesGetLinks(
				artworkDesire.images,
				"artworkDesires"
			);
			artworkDesire.imagesURLs = (artworkDesire.imagesURLs || []).concat(
				imagesURLs
			);
		}
		if (artworkDesire.files && artworkDesire.files?.length) {
			let filesURLs = await uploadFilesGetLinks(
				artworkDesire.files,
				"artworkDesires"
			);

			artworkDesire.filesURLs = (artworkDesire.filesURLs || []).concat(
				filesURLs
			);
		}
	}
	return authClient().put(`${API}/supplier/${id}`, supplier);
};

export const deleteSupplier = (id) => {
	return authClient().delete(`${API}/supplier/${id}`);
};
