import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../../../../src/customStyles/preview.css";
import { getFileType } from "../../../utils/helpers";

const PreviewDialog = (props) => {
	const { title, open, src, setOpen, file } = props;

	const [type, setType] = useState(
		file?.name ? getFileType(file?.name) : getFileType(src)
	);

	useEffect(() => {
		setType(file?.name ? getFileType(file?.name) : getFileType(src));
	}, [open]);

	return (
		<Dialog
			fullWidth={type !== "image"}
			maxWidth="lg"
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="confirm-dialog"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent
				className={
					type === "video" || type === "file"
						? "preview-container"
						: ""
				}
			>
				<div style={{ display: "flex", justifyContent: "center" }}>
					{type === "image" ? (
						<img
							className={"preview-image"}
							src={src}
							height={"100%"}
							width={"100%"}
						/>
					) : type === "video" ? (
						<video className="preview-video" controls>
							<source src={src} />
						</video>
					) : (
						<embed
							className={"preview-file"}
							style={{
								minHeight: "60vh",
								width: "100vh",
								height: "70vh",
							}}
							src={src}
							height={"100%"}
							width={"100%"}
						/>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={() => {
						setOpen(false);
					}}
					color="default"
				>
					Ok!
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default PreviewDialog;
