import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	deleteArtworkType,
	getArtworkTypes,
} from "../../../../api/artworkType";
import { postLog } from "../../../../api/log";
import { LOGS } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	getCurrentTablePage,
} from "../../../components/tables/table";

function getData(artworkTypes) {
	let data = [];
	for (let i = 0; i < artworkTypes.length; ++i) {
		const elem = {};
		const artworkType = artworkTypes[i];

		elem.title = artworkType?.title;
		elem.createdAt = artworkType?.createdAt;
		elem.id = artworkType?._id;

		data = data.concat(elem);
	}
	return data;
}

export default function ArtworkTypesPage() {
	const [data, setData] = useState([]);
	const [selectedArtworkType, setSelectedArtworkType] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);

	const location = useLocation();

	const history = useHistory();

	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push({
								pathname: "/artwork-types/" + cell + "/edit",
								state: {
									page: getCurrentTablePage(location),
								},
							});
						}}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedArtworkType(elem);
							setOpenConfirmDialog(true);
						}}
					>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "createdAt",
			text: "Created At",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getArtworkTypes()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artwork types.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Artwork type list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								history.push({
									pathname: "/artwork-types/edit",
									state: {
										page: getCurrentTablePage(location),
									},
								});
							}}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={getData(data)} columns={columns} />
					<ConfirmDialog
						title={
							"Are you sure you want to delete this artwork type?"
						}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteArtworkType(selectedArtworkType?._id)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										postLog(
											loggedUser,
											LOGS.FIELDS.ARTWORK_TYPES,
											`${LOGS.ACTIONS.DELETE}-${selectedArtworkType?.title}`
										);
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Artwork Type deleted successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not delete artwork type.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
