import {
	AccountTree,
	Brush,
	Category,
	PhotoLibrary,
	Public,
	Store,
	SupervisorAccount,
	Timeline,
} from "@material-ui/icons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getArtists } from "../../api/artist";
import { getArtistTypes } from "../../api/artistType";
import { getArtisticMovements } from "../../api/artisticMovement";
import { getArtworks } from "../../api/artwork";
import { getArtworkTypes } from "../../api/artworkType";
import { getCountries } from "../../api/country";
import { getSuppliers } from "../../api/supplier";
import { countAdmins, countManagers } from "../../api/user";
import { alertError } from "../../utils/logger";

export function DashboardPage() {
	const [data, setData] = useState([]);
	const history = useHistory();

	useEffect(() => {
		async function fetchData() {
			try {
				const admins = await countAdmins();
				const managers = await countManagers();
				const artworks = await getArtworks();
				const suppliers = await getSuppliers();
				const artists = await getArtists();
				const artworkTypes = await getArtworkTypes();
				const artistTypes = await getArtistTypes();
				const artisticMovements = await getArtisticMovements();
				const countries = await getCountries();

				setData({
					numAdmins: admins.data,
					numManagers: managers.data,
					numArtworks: artworks?.data?.length,
					numSuppliers: suppliers?.data?.length,
					numArtists: artists?.data?.length,
					numArtworkTypes: artworkTypes?.data?.length,
					numArtistTypes: artistTypes?.data?.length,
					numArtisticMovements: artisticMovements?.data?.length,
					numCountries: countries?.data?.length,
				});
			} catch (err) {
				alertError({
					error: err,
					customMessage: "Could not get statistics.",
				});
			}
		}
		fetchData();
	}, []);

	return (
		<>
			<div className="row justify-content-center">
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/admins")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<AssignmentIndIcon /> Administrators
							</h5>
							<div className="contentDash">{data.numAdmins}</div>
						</div>
					</div>
				</div>
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/admins")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<SupervisorAccount /> Managers
							</h5>
							<div className="contentDash">
								{data.numManagers}
							</div>
						</div>
					</div>
				</div>
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/suppliers")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<Store /> Suppliers
							</h5>
							<div className="contentDash">
								{data.numSuppliers}
							</div>
						</div>
					</div>
				</div>
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/artworks")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<PhotoLibrary /> Artworks
							</h5>
							<div className="contentDash">
								{data.numArtworks}
							</div>
						</div>
					</div>
				</div>
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/artists")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<Brush /> Artists
							</h5>
							<div className="contentDash">{data.numArtists}</div>
						</div>
					</div>
				</div>
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/artwork-types")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<Category /> Artwork Types
							</h5>
							<div className="contentDash">
								{data.numArtworkTypes}
							</div>
						</div>
					</div>
				</div>
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/artist-types")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<AccountTree /> Artist Types
							</h5>
							<div className="contentDash">
								{data.numArtistTypes}
							</div>
						</div>
					</div>
				</div>
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/artistic-movements")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<Timeline /> Artistic Movements
							</h5>
							<div className="contentDash">
								{data.numArtisticMovements}
							</div>
						</div>
					</div>
				</div>
				<div
					className="col-12 col-md-4 text-center my-2"
					role="button"
					onClick={() => history.push("/countries")}
				>
					<div className="card">
						<div className="card-body">
							<h5 className="card-title">
								<Public /> Countries
							</h5>
							<div className="contentDash">
								{data.numCountries}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
