import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tooltip,
} from "@material-ui/core";
import {
	Comment,
	InsertDriveFile,
	Launch,
	PhotoLibrary,
} from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SERVER_URL } from "../../../api";
import ItemCountBadge from "../ItemCountBadge";
import Table, { buttonsStyle } from "../tables/table";
import CommentDialog from "./CommentDialog";
import FilePickerDialog from "./FilePickerDialog";
import ImageCarouselDialog from "./ImageCarouselDialog";
import ImagePickerDialog from "./ImagePickerDialog";

function getData(data) {
	let result = [];
	for (let i = 0; i < data.length; ++i) {
		const elem = {};
		const artwork = data[i].artwork || data[i];

		elem.title = artwork?.title;
		elem.artworkType = artwork?.artworkType?.title;
		elem.images = [
			artwork?.imageURL,
			...(artwork?.artworkSuppliers?.flatMap((x) => x.imagesURLs) || []),
			...(artwork?.artworkDesires?.flatMap((x) => x.imagesURLs) || []),
		]?.filter((x) => x);
		elem.artist = artwork?.artist?.fullName;
		elem.description = artwork?.description;
		elem.deleted = artwork?.deleted;
		elem.id = artwork?._id;

		result = result.concat(elem);
	}
	return result;
}

const ArtworkSupplierTableDialog = (props) => {
	const {
		title,
		open,
		setOpen,
		data,
		artworkSupplierTable,
		desireTable,
	} = props;

	const history = useHistory();

	const [selectedArtworkSupplier, setSelectedArtworkSupplier] = useState(
		null
	);

	const [carouselImages, setCarouselImages] = useState([]);
	const [openCarouselDialog, setOpenCarouselDialog] = useState(false);

	const [openCommentDialog, setOpenCommentDialog] = useState(false);
	const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
	const [openFilePickerDialog, setOpenFilePickerDialog] = useState(false);

	function imageFormatterCarousel(images) {
		return images && images?.length > 0 ? (
			<img
				src={
					typeof images[0] === "string"
						? SERVER_URL + "/" + images[0]
						: URL.createObjectURL(images[0])
				}
				alt="artwork"
				style={{ width: "50px", cursor: "zoom-in" }}
				onClick={() => {
					setCarouselImages(images);
					setOpenCarouselDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		const artworkSupplier = artworkSupplierTable
			? data?.artworks
					?.find((x) => x._id === cell)
					?.artworkSuppliers?.find((x) => x.supplier === data?._id)
			: desireTable
			? data?.artworkDesires?.find((x) => x.artwork?._id === cell)
			: null;

		return (
			<>
				<Tooltip title={"Comments"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenCommentDialog(true);
							setSelectedArtworkSupplier(artworkSupplier);
						}}
					>
						<Comment />
						{artworkSupplier?.comments && (
							<ItemCountBadge howMany={1} />
						)}
					</Button>
				</Tooltip>
				<Tooltip title={"Images"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenImagePickerDialog(true);
							setSelectedArtworkSupplier(artworkSupplier);
						}}
					>
						<PhotoLibrary />
						{artworkSupplier?.imagesURLs?.length > 0 && (
							<ItemCountBadge
								howMany={artworkSupplier?.imagesURLs?.length}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip title={"Documentation"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenFilePickerDialog(true);
							setSelectedArtworkSupplier(artworkSupplier);
						}}
					>
						<InsertDriveFile />
						{artworkSupplier?.filesURLs?.length > 0 && (
							<ItemCountBadge
								howMany={artworkSupplier?.filesURLs?.length}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip title={"See artwork"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push("/artworks/" + cell);
						}}
					>
						<Launch />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{
			dataField: "images",
			text: "",
			formatter: imageFormatterCarousel,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "artworkType",
			text: "Artwork Type",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "artist",
			text: "Artist",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "id",
			text: "",
			formatter: buttonFormatter,
		},
	];

	return (
		<Dialog
			fullWidth={true}
			maxWidth="xl"
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				{(artworkSupplierTable &&
					(!data?.artworks || !data?.artworks?.length)) ||
				(desireTable &&
					(!data?.artworkDesires ||
						!data?.artworkDesires?.length)) ? (
					<p>{"Could not find artworks"}</p>
				) : (
					<Table
						data={getData(
							artworkSupplierTable
								? data?.artworks
								: desireTable
								? data?.artworkDesires
								: []
						)}
						columns={columns}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					variant="outlined"
					color="secondary"
				>
					Cancel
				</Button>
			</DialogActions>
			<CommentDialog
				title={"Additional Comments"}
				open={openCommentDialog}
				setOpen={setOpenCommentDialog}
				data={selectedArtworkSupplier?.comments}
				editable={false}
			/>
			<ImagePickerDialog
				title={"Images"}
				open={openImagePickerDialog}
				setOpen={setOpenImagePickerDialog}
				data={selectedArtworkSupplier?.imagesURLs}
				editable={false}
			/>
			<FilePickerDialog
				title={"Archivos"}
				open={openFilePickerDialog}
				setOpen={setOpenFilePickerDialog}
				data={selectedArtworkSupplier?.filesURLs}
				editable={false}
			/>
			<ImageCarouselDialog
				open={openCarouselDialog}
				setOpen={setOpenCarouselDialog}
				images={carouselImages}
			/>
		</Dialog>
	);
};
export default ArtworkSupplierTableDialog;
