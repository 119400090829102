import { API, authClient } from "../index";

export const postArtisticMovement = (artisticMovement) => {
	return authClient().post(`${API}/artistic-movement`, artisticMovement);
};

export const getArtisticMovements = () => {
	return authClient().get(`${API}/artistic-movement`);
};

export const getArtisticMovementById = (id) => {
	return authClient().get(`${API}/artistic-movement/${id}`);
};

export const updateArtisticMovement = (id, artisticMovement) => {
	return authClient().put(`${API}/artistic-movement/${id}`, artisticMovement);
};

export const deleteArtisticMovement = (id) => {
	return authClient().delete(`${API}/artistic-movement/${id}`);
};
