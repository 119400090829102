import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import ArtisticMovementsPage from "./modules/ContentManager/artisticMovements/ArtisticMovementsPage";
import EditArtisticMovementsPage from "./modules/ContentManager/artisticMovements/EditArtisticMovementsPage";
import ArtistsPage from "./modules/ContentManager/artists/ArtistsPage";
import EditArtistsPage from "./modules/ContentManager/artists/EditArtistsPage";
import ArtistTypesPage from "./modules/ContentManager/artistTypes/ArtistTypesPage";
import EditArtistTypesPage from "./modules/ContentManager/artistTypes/EditArtistTypesPage";
import ArtworksPage from "./modules/ContentManager/artworks/ArtworksPage";
import EditArtworksPage from "./modules/ContentManager/artworks/EditArtworksPage";
import ViewArtworksPage from "./modules/ContentManager/artworks/ViewArtworksPage";
import ArtworkTypesPage from "./modules/ContentManager/artworkTypes/ArtworkTypesPage";
import EditArtworkTypesPage from "./modules/ContentManager/artworkTypes/EditArtworkTypesPage";
import CountriesPage from "./modules/ContentManager/countries/CountriesPage";
import EditCountriesPage from "./modules/ContentManager/countries/EditCountriesPage";
import LogsPage from "./modules/ContentManager/logs/LogsPage";
import EditSuppliersPage from "./modules/ContentManager/suppliers/EditSuppliersPage";
import SuppliersPage from "./modules/ContentManager/suppliers/SuppliersPage";
import UpdatesPage from "./modules/ContentManager/updates/UpdatesPage";
import { DashboardPage } from "./pages/DashboardPage";

export default function BasePage() {
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const isAdmin = user && user?.role !== "admin";

	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */}
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />

			{/* USERS */}
			{/* Adminis */}
			<ContentRoute from="/admins/:id?/edit" component={EditAdminsPage} />
			<ContentRoute from="/admins" component={AdminsPage} />

			{/* COMMERCIAL */}
			{/* Suppliers */}
			<ContentRoute
				from="/suppliers/:id?/edit"
				component={EditSuppliersPage}
			/>
			<ContentRoute from="/suppliers" component={SuppliersPage} />

			{/* ARTISTS */}
			{/* Artists */}
			<ContentRoute
				from="/artists/:id?/edit"
				component={EditArtistsPage}
			/>
			<ContentRoute from="/artists" component={ArtistsPage} />

			{/* Artist types */}
			<ContentRoute
				from="/artist-types/:id?/edit"
				component={EditArtistTypesPage}
			/>
			<ContentRoute from="/artist-types" component={ArtistTypesPage} />

			{/* Artistic movements */}
			<ContentRoute
				from="/artistic-movements/:id?/edit"
				component={EditArtisticMovementsPage}
			/>
			<ContentRoute
				from="/artistic-movements"
				component={ArtisticMovementsPage}
			/>

			{/* ARTWORKS */}
			{/* Artwork types */}
			<ContentRoute
				from="/artwork-types/:id?/edit"
				component={EditArtworkTypesPage}
			/>
			<ContentRoute from="/artwork-types" component={ArtworkTypesPage} />

			{/* Artworks */}
			<ContentRoute
				from="/artworks/:id?/edit"
				component={EditArtworksPage}
			/>
			<ContentRoute from="/artworks/:id" component={ViewArtworksPage} />
			<ContentRoute from="/artworks" component={ArtworksPage} />

			{/* OTHER */}
			{/* Countries */}
			<ContentRoute
				from="/countries/:id?/edit"
				component={EditCountriesPage}
			/>
			<ContentRoute from="/countries" component={CountriesPage} />

			{/* Logs */}
			<ContentRoute from="/logs" component={LogsPage} />

			{/* Updates */}
			<ContentRoute from="/updates" component={UpdatesPage} />

			<Redirect to="/error" />
		</Switch>
	);
}
