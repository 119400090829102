import { API, authClient } from "../index";

export const postArtistType = (artistType) => {
	return authClient().post(`${API}/artist-type`, artistType);
};

export const getArtistTypes = () => {
	return authClient().get(`${API}/artist-type`);
};

export const getArtistTypeById = (id) => {
	return authClient().get(`${API}/artist-type/${id}`);
};

export const updateArtistType = (id, artistType) => {
	return authClient().put(`${API}/artist-type/${id}`, artistType);
};

export const deleteArtistType = (id) => {
	return authClient().delete(`${API}/artist-type/${id}`);
};
