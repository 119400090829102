import { API, authClient } from "../index";

export const postArtist = (artist) => {
	return authClient().post(`${API}/artist`, artist);
};

export const getArtists = () => {
	return authClient().get(`${API}/artist`);
};

export const getArtistById = (id) => {
	return authClient().get(`${API}/artist/${id}`);
};

export const updateArtist = (id, artist) => {
	return authClient().put(`${API}/artist/${id}`, artist);
};

export const deleteArtist = (id) => {
	return authClient().delete(`${API}/artist/${id}`);
};
