import { uploadFilesGetLinks, uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const postArtwork = async (artwork, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "artworks");
		artwork.imageURL = response?.data?.fileURL;
	}
	for (const artworkSupplier of artwork?.artworkSuppliers) {
		if (artworkSupplier.images && artworkSupplier.images?.length) {
			let imagesURLs = await uploadFilesGetLinks(
				artworkSupplier.images,
				"artworkSuppliers"
			);
			artworkSupplier.imagesURLs = imagesURLs;
		}
		if (artworkSupplier.files && artworkSupplier.files?.length) {
			let filesURLs = await uploadFilesGetLinks(
				artworkSupplier.files,
				"artworkSuppliers"
			);
			artworkSupplier.filesURLs = filesURLs;
		}
	}
	return authClient().post(`${API}/artwork`, artwork);
};

export const getArtworks = (imageURL, getDeleted) => {
	return authClient().get(
		`${API}/artwork?${
			imageURL ? `imageURL=${imageURL}` : ""
		}&getDeleted=${getDeleted}`
	);
};

export const getArtworkById = (id) => {
	return authClient().get(`${API}/artwork/${id}`);
};

export const updateArtwork = async (id, artwork, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "artworks");
		artwork.imageURL = response?.data?.fileURL;
	}
	for (const artworkSupplier of artwork?.artworkSuppliers) {
		if (artworkSupplier.images && artworkSupplier.images?.length) {
			let imagesURLs = await uploadFilesGetLinks(
				artworkSupplier.images,
				"artworkSuppliers"
			);

			artworkSupplier.imagesURLs = (
				artworkSupplier.imagesURLs || []
			).concat(imagesURLs);
		}
		if (artworkSupplier.files && artworkSupplier.files?.length) {
			let filesURLs = await uploadFilesGetLinks(
				artworkSupplier.files,
				"artworkSuppliers"
			);

			artworkSupplier.filesURLs = (
				artworkSupplier.filesURLs || []
			).concat(filesURLs);
		}
	}
	return authClient().put(`${API}/artwork/${id}`, artwork);
};

export const changeStatusArtwork = async (id, deleted) => {
	return authClient().put(`${API}/artwork/change-status/${id}`, { deleted });
};

export const deleteArtwork = (id) => {
	return authClient().delete(`${API}/artwork/${id}`);
};
