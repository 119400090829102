import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getUpdates } from "../../../../api/update";
import { alertError } from "../../../../utils/logger";
import Table, { dateFormatter } from "../../../components/tables/table";

function getData(updates) {
	let data = [];
	for (let i = 0; i < updates.length; ++i) {
		const elem = {};
		elem.version = updates[i].version;
		elem.details = updates[i].details ? updates[i].details : "No details";
		elem.createdAt = updates[i].createdAt;
		data = data.concat(elem);
	}
	return data;
}

export default function UpdatesPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalMessage, setModalMessage] = useState("");

	const columns = [
		{ dataField: "version", text: "Version", sort: true },
		{ dataField: "details", text: "Details", sort: true },
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
		},
	];

	useEffect(() => {
		getUpdates()
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data);
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get updates. ",
				});
			});
	}, [refresh]);

	function UpdateModal({ show, message, onClose }) {
		if (!show) return null;

		return (
			<div
				style={{
					position: "fixed",
					top: "50%",
					left: "50%",
					backgroundColor: "white",
					padding: "20px",
					zIndex: 1000,
					borderRadius: "4px",
					boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
					width: "300px",
					borderRadius: "4px",
					border: "1px solid #dcdcdc",
				}}
			>
				<p>{message}</p>
				<button className="btn btn-primary" onClick={onClose}>
					Close
				</button>
			</div>
		);
	}

	const handleSearchUpdates = () => {
		setShowModal(true);
		setModalMessage("Searching for updates...");

		const randomTime = Math.floor(Math.random() * (7000 - 3000 + 1)) + 3000;

		setTimeout(() => {
			setModalMessage("No updates available.");

			setTimeout(() => setShowModal(false), 2000);
		}, randomTime);
	};

	return (
		<>
			<Card>
				<CardHeader title="Update history">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => handleSearchUpdates()}
						>
							Search updates
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
			<UpdateModal
				show={showModal}
				message={modalMessage}
				onClose={() => setShowModal(false)}
			/>
		</>
	);
}
