import { API, authClient } from "../index";

export const postArtworkType = (artworkType) => {
	return authClient().post(`${API}/artwork-type`, artworkType);
};

export const getArtworkTypes = () => {
	return authClient().get(`${API}/artwork-type`);
};

export const getArtworkTypeById = (id) => {
	return authClient().get(`${API}/artwork-type/${id}`);
};

export const updateArtworkType = (id, artworkType) => {
	return authClient().put(`${API}/artwork-type/${id}`, artworkType);
};

export const deleteArtworkType = (id) => {
	return authClient().delete(`${API}/artwork-type/${id}`);
};
