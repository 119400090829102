import axios from "axios";
import { API, authClient, PRO } from "../index";

export const postLog = async (user, field, action) => {
	const response = await axios.get("https://api.ipify.org/?format=json");
	const body = {
		user: user?._id,
		userName: user?.fullName,
		field,
		action,
		userIP: response?.data?.ip,
	};

	if (PRO) return authClient().post(`${API}/log`, body);
};

export const getLogs = () => {
	return authClient().get(`${API}/log`);
};
