import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

function getEmptyContact() {
	return {
		fullName: "",
		email: null,
		phone: null,
	};
}

const EditContactDialog = (props) => {
	const { open, setOpen, data, onSave } = props;
	const [contact, setContact] = useState({ ...getEmptyContact(), ...data });

	useEffect(() => {
		if (open) setContact({ ...getEmptyContact(), ...data });
	}, [open]);

	const handleChange = (element) => (event) => {
		setContact({ ...contact, [element]: event.target.value });
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth="md"
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{"Edit Contact"}</DialogTitle>
			<DialogContent>
				<TextField
					id={`fullName`}
					label="Name"
					value={contact.fullName}
					onChange={handleChange("fullName")}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<div className="row d-flex align-items-center">
					<div className="col-md-6">
						<TextField
							id={`email`}
							label="Email"
							value={contact.email}
							onChange={handleChange("email")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
						/>
					</div>
					<div className="col-md-6">
						<TextField
							id={`phone`}
							label="Teléfono"
							value={contact.phone}
							onChange={handleChange("phone")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					variant="outlined"
					color="secondary"
				>
					Cancel
				</Button>
				<Button
					className="ml-3"
					onClick={() => {
						onSave(contact);
						setOpen(false);
						setContact(getEmptyContact());
					}}
					variant="outlined"
					color="primary"
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditContactDialog;
