import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Editor from "../editor/Editor";

function getData(suppliers) {
	let data = [];
	for (let i = 0; i < suppliers.length; ++i) {
		const elem = {};
		const supplier = suppliers[i];

		elem.fullName = supplier?.fullName;
		elem.town = supplier?.town;
		elem.province = supplier?.province;
		elem.createdAt = supplier?.createdAt;
		elem.id = supplier?._id;

		data = data.concat(elem);
	}
	return data;
}

const CommentDialog = (props) => {
	const { title, open, setOpen, data, onSave, editable = true } = props;

	const [comment, setComment] = useState(data);

	const handleChangeEditor = (value) => {
		setComment(value);
	};

	useEffect(() => {
		if (open) setComment(data);
	}, [open]);

	return (
		<Dialog
			fullWidth={true}
			maxWidth="md"
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				{editable ? (
					<Editor
						body={comment || ""}
						setBody={(new_body) => {
							handleChangeEditor(new_body);
						}}
						className="max-height"
						placeholder={"Enter comments here..."}
					/>
				) : (
					<>
						{comment ? (
							<div
								dangerouslySetInnerHTML={{ __html: comment }}
							/>
						) : (
							<div>No comments available</div>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					variant="outlined"
					color="secondary"
				>
					Cancel
				</Button>
				{editable && (
					<Button
						className="ml-3"
						onClick={() => {
							onSave(comment);
							setOpen(false);
							setComment(null);
						}}
						variant="outlined"
						color="primary"
					>
						Save
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
export default CommentDialog;
