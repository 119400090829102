/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {
	AccountTree,
	Brush,
	Category,
	PhotoLibrary,
	Public,
	Store,
	Timeline,
	Update,
	VpnKey,
} from "@material-ui/icons";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import React from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					"menu-item-active"} menu-item-open menu-item-not-hightlighted`
			: "";
	};

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<li
					className={`menu-item ${getMenuItemActive(
						"/dashboard",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/dashboard">
						<span className="svg-icon menu-icon">
							<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/Design/Layers.svg"
								)}
							/>
						</span>
						<span className="menu-text">Dashboard</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">USERS</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/admins",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/admins">
						<span className="menu-icon">
							<AssignmentIndIcon />
						</span>
						<span className="menu-text">Administrators</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">COMMERCIAL</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/suppliers",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/suppliers">
						<span className="menu-icon">
							<Store />
						</span>
						<span className="menu-text">Suppliers</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">ARTWORKS</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/artworks",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/artworks">
						<span className="menu-icon">
							<PhotoLibrary />
						</span>
						<span className="menu-text">Artworks</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/artwork-types",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/artwork-types">
						<span className="menu-icon">
							<Category />
						</span>
						<span className="menu-text">Artwork Types</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">Artists</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/artists",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/artists">
						<span className="menu-icon">
							<Brush />
						</span>
						<span className="menu-text">Artists</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/artist-types",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/artist-types">
						<span className="menu-icon">
							<AccountTree />
						</span>
						<span className="menu-text">Artist Types</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/artistic-movements",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/artistic-movements">
						<span className="menu-icon">
							<Timeline />
						</span>
						<span className="menu-text">Artistic Movements</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">OTHER</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/countries",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/countries">
						<span className="menu-icon">
							<Public />
						</span>
						<span className="menu-text">Countries</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive("/logs", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/logs">
						<span className="menu-icon">
							<VpnKey />
						</span>
						<span className="menu-text">Logs</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						"/updates",
						false
					)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/updates">
						<span className="menu-icon">
							<Update />
						</span>
						<span className="menu-text">Updates</span>
					</NavLink>
				</li>
			</ul>

			{/* end::Menu Nav */}
		</>
	);
}
