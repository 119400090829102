import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { deleteCountry, getCountries } from "../../../../api/country";
import { postLog } from "../../../../api/log";
import { LOGS } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	getCurrentTablePage,
} from "../../../components/tables/table";

function getData(countries) {
	let data = [];
	for (let i = 0; i < countries.length; ++i) {
		const elem = {};
		const country = countries[i];

		elem.name = country?.name;
		elem.createdAt = country?.createdAt;
		elem.id = country?._id;

		data = data.concat(elem);
	}
	return data;
}

export default function CountriesPage() {
	const [data, setData] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);

	const location = useLocation();

	const history = useHistory();

	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push({
								pathname: "/countries/" + cell + "/edit",
								state: {
									page: getCurrentTablePage(location),
								},
							});
						}}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedCountry(elem);
							setOpenConfirmDialog(true);
						}}
					>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: "name", text: "Name", sort: true },
		{
			dataField: "createdAt",
			text: "Created At",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getCountries()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get countries.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Country list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								history.push({
									pathname: "/countries/edit",
									state: {
										page: getCurrentTablePage(location),
									},
								});
							}}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={getData(data)} columns={columns} />
					<ConfirmDialog
						title={"Are you sure you want to delete this country?"}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteCountry(selectedCountry?._id)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										postLog(
											loggedUser,
											LOGS.FIELDS.COUNTRIES,
											`${LOGS.ACTIONS.DELETE}-${selectedCountry?.name}`
										);
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Country deleted successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not delete country.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
