import {
	Button,
	createMuiTheme,
	MuiThemeProvider,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	deleteArtist,
	getArtistById,
	postArtist,
	updateArtist,
} from "../../../../api/artist";
import { getArtisticMovements } from "../../../../api/artisticMovement";
import { getArtistTypes } from "../../../../api/artistType";
import { getCountries } from "../../../../api/country";
import { postLog } from "../../../../api/log";
import { LOGS } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyArtist() {
	return {
		fullName: "",
		nationality: [],
		artistType: [],
		artisticMovement: [],
		description: "",
	};
}

export default function EditArtistsPage() {
	const [artist, setArtist] = useState(getEmptyArtist());

	const [countries, setCountries] = useState([]);
	const [artistTypes, setArtistTypes] = useState([]);
	const [artisticMovements, setArtisticMovements] = useState([]);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const artistId = useParams().id;
	const history = useHistory();
	const location = useLocation();

	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getCountries()
			.then((res) => {
				if (res.status === 200) {
					setCountries(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get countries.",
				});
				handleBack();
			});
		getArtisticMovements()
			.then((res) => {
				if (res.status === 200) {
					setArtisticMovements(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artistic movements.",
				});
				handleBack();
			});
		getArtistTypes()
			.then((res) => {
				if (res.status === 200) {
					setArtistTypes(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artist types.",
				});
				handleBack();
			});
		if (!artistId) {
			disableLoadingData();
			return;
		}
		getArtistById(artistId)
			.then((res) => {
				if (res.status === 200) {
					setArtist(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artist.",
				});
				handleBack();
			});
	}, [artistId, disableLoadingData, history]);

	const handleBack = () => {
		const prevTablePage = location?.state?.page;

		history.push(
			`/artists${prevTablePage ? "?page=" + prevTablePage : ""}`
		);
	};

	function saveArtist() {
		if (!artistId) {
			postArtist(artist)
				.then((res) => {
					if (res.status === 201) {
						postLog(
							loggedUser,
							LOGS.FIELDS.ARTISTS,
							`${LOGS.ACTIONS.CREATE}-${artist?.fullName}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Artist created successfully.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save artist.",
					});
				});
		} else {
			updateArtist(artistId, artist)
				.then((res) => {
					if (res.status === 200) {
						postLog(
							loggedUser,
							LOGS.FIELDS.ARTISTS,
							`${LOGS.ACTIONS.UPDATE}-${artist?.fullName}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setArtist({ ...artist, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit artist"></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label="Full Name"
							value={artist.fullName}
							onChange={handleChange("fullName")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<TextField
									id={`birthDate`}
									label="Birth Date"
									value={artist.birthDate || ""}
									onChange={handleChange("birthDate")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									required
								/>
							</div>
							<div className="col-md-6">
								<Autocomplete
									id="autocomplete-country"
									multiple
									disablePortal
									disableClearable
									filterSelectedOptions
									options={countries}
									getOptionLabel={(option) => option.name}
									value={
										countries?.filter((x) =>
											artist?.nationality?.includes(
												x?._id
											)
										) || ""
									}
									onChange={(event, selected) => {
										setArtist({
											...artist,
											nationality: selected?.map(
												(x) => x._id
											),
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Nationality"
											margin="normal"
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
											required
										/>
									)}
								/>
							</div>
						</div>
						<Autocomplete
							id="autocomplete-artistType"
							multiple
							disablePortal
							disableClearable
							filterSelectedOptions
							disableCloseOnSelect
							options={artistTypes}
							getOptionLabel={(option) => option.title}
							value={
								artistTypes?.filter((x) =>
									artist?.artistType?.includes(x._id)
								) || ""
							}
							onChange={(event, selected) => {
								setArtist({
									...artist,
									artistType: selected?.map((x) => x._id),
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Artist Type"
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									required
								/>
							)}
						/>
						<Autocomplete
							id="autocomplete-movement"
							multiple
							disablePortal
							disableClearable
							filterSelectedOptions
							disableCloseOnSelect
							options={artisticMovements}
							getOptionLabel={(option) => option.name}
							value={
								artisticMovements?.filter((x) =>
									artist?.artisticMovement?.includes(x._id)
								) || ""
							}
							onChange={(event, selected) => {
								setArtist({
									...artist,
									artisticMovement: selected?.map(
										(x) => x._id
									),
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Artistic Movement"
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						/>
						<TextField
							id={`descripcion`}
							label="Description"
							value={artist.description}
							onChange={handleChange("description")}
							InputLabelProps={{
								shrink: true,
							}}
							multiline
							rows={5}
							margin="normal"
							variant="outlined"
						/>
					</CardBody>
				</Card>
				<Button
					onClick={handleBack}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Back
				</Button>
				<Button
					onClick={() => saveArtist()}
					variant="outlined"
					color="primary"
					style={{ marginRight: "20px" }}
				>
					Save artist
				</Button>
				{artistId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								className="py-2"
								onClick={() => setOpenConfirmDialog(true)}
								variant="outlined"
								color="secondary"
							>
								Delete artist
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={
								"Are you sure you want to delete this artist?"
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteArtist(artistId)
									.then((res) => {
										if (
											res.status === 204 ||
											res.status === 200
										) {
											postLog(
												loggedUser,
												LOGS.FIELDS.ARTISTS,
												`${LOGS.ACTIONS.DELETE}-${artist?.fullName}`
											);
											alertSuccess({
												title: "Deleted!",
												customMessage:
													"Artist deleted successfully.",
											});
											handleBack();
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												"Could not delete artist.",
										});
									});
							}}
						/>
					</>
				)}
			</>
		);
}
