import {
	Button,
	createMuiTheme,
	MuiThemeProvider,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	deleteArtworkType,
	getArtworkTypeById,
	postArtworkType,
	updateArtworkType,
} from "../../../../api/artworkType";
import { postLog } from "../../../../api/log";
import { LOGS } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyArtworkType() {
	return {
		title: "",
	};
}

export default function EditArtworkTypesPage() {
	const [artworkType, setArtworkType] = useState(getEmptyArtworkType());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const artworkTypeId = useParams().id;
	const history = useHistory();
	const location = useLocation();

	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!artworkTypeId) {
			disableLoadingData();
			return;
		}
		getArtworkTypeById(artworkTypeId)
			.then((res) => {
				if (res.status === 200) {
					setArtworkType(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artwork type.",
				});
				handleBack();
			});
	}, [artworkTypeId, disableLoadingData, history]);

	const handleBack = () => {
		const prevTablePage = location?.state?.page;

		history.push(
			`/artwork-types${prevTablePage ? "?page=" + prevTablePage : ""}`
		);
	};

	function saveArtworkType() {
		if (!artworkTypeId) {
			postArtworkType(artworkType)
				.then((res) => {
					if (res.status === 201) {
						postLog(
							loggedUser,
							LOGS.FIELDS.ARTWORK_TYPES,
							`${LOGS.ACTIONS.CREATE}-${artworkType?.title}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Artwork Type created successfully.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save artwork type.",
					});
				});
		} else {
			updateArtworkType(artworkTypeId, artworkType)
				.then((res) => {
					if (res.status === 200) {
						postLog(
							loggedUser,
							LOGS.FIELDS.ARTWORK_TYPES,
							`${LOGS.ACTIONS.UPDATE}-${artworkType?.title}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setArtworkType({ ...artworkType, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit Artwork Type"></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label="Title"
							value={artworkType.title}
							onChange={handleChange("title")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
					</CardBody>
				</Card>
				<Button
					onClick={handleBack}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Back
				</Button>
				<Button
					onClick={() => saveArtworkType()}
					variant="outlined"
					color="primary"
					style={artworkTypeId && { marginRight: "20px" }}
				>
					Save Artwork Type
				</Button>
				{artworkTypeId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								className="py-2"
								onClick={() => setOpenConfirmDialog(true)}
								variant="outlined"
								color="secondary"
							>
								Delete Artwork Type
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={
								"Are you sure you want to delete this artwork type?"
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteArtworkType(artworkTypeId)
									.then((res) => {
										if (
											res.status === 204 ||
											res.status === 200
										) {
											postLog(
												loggedUser,
												LOGS.FIELDS.ARTWORK_TYPES,
												`${LOGS.ACTIONS.DELETE}-${artworkType?.title}`
											);
											alertSuccess({
												title: "Deleted!",
												customMessage:
													"Artwork Type deleted successfully.",
											});
											handleBack();
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												"Could not delete artwork type.",
										});
									});
							}}
						/>
					</>
				)}
			</>
		);
}
