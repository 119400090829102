import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tooltip,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SERVER_URL } from "../../../api";
import Table, { buttonsStyle } from "../tables/table";
import ImageCarouselDialog from "./ImageCarouselDialog";

function getData(artworks) {
	let data = [];
	for (let i = 0; i < artworks.length; ++i) {
		const elem = {};
		const artwork = artworks[i];

		elem.imagesURLs = [
			artwork?.imageURL,
			...(artwork?.artworkSuppliers?.flatMap((x) => x.imagesURLs) || []),
		];
		elem.title = artwork?.title;
		elem.artworkType = artwork?.artworkType?.title;
		elem.imageURL = artwork?.imageURL;
		elem.artist = artwork?.artist?.fullName;
		elem.description = artwork?.description;
		elem.id = artwork?._id;

		data = data.concat(elem);
	}
	return data;
}

const ArtworkTableDialog = (props) => {
	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [previewImages, setPreviewImages] = useState(null);

	const { title, subtitle, open, setOpen, data, onSelectRow, onSave } = props;

	function imageFormatter(imagesURLs) {
		return imagesURLs && imagesURLs?.length > 0 ? (
			<img
				src={SERVER_URL + "/" + imagesURLs[0]}
				alt="artwork"
				style={{ width: "50px", cursor: "zoom-in" }}
				onClick={() => {
					setPreviewImages(imagesURLs);
					setOpenPreviewDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		return (
			<>
				{onSelectRow && (
					<Tooltip title={"Select"}>
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() => {
								onSelectRow(cell);
								setOpen(false);
							}}
						>
							<AddCircle />
						</Button>
					</Tooltip>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "imagesURLs",
			text: "",
			formatter: imageFormatter,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "artworkType",
			text: "Artwork Type",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "artist",
			text: "Artist",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "id",
			text: "",
			formatter: buttonFormatter,
		},
	];

	return (
		<Dialog
			fullWidth={true}
			maxWidth="xl"
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">
				<div className="d-flex justify-content-between">{title}</div>
			</DialogTitle>
			<DialogContent>
				{subtitle}
				<>
					{!data || !data?.length ? (
						<p>{"No available artworks"}</p>
					) : (
						<Table data={getData(data)} columns={columns} />
					)}
				</>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					variant="outlined"
					color="secondary"
				>
					Cancel
				</Button>
				{onSave && (
					<Button
						className="ml-3"
						onClick={() => {
							if (onSave) onSave();
						}}
						variant="outlined"
						color="primary"
					>
						Save artwork
					</Button>
				)}
			</DialogActions>
			<ImageCarouselDialog
				open={openPreviewDialog}
				setOpen={setOpenPreviewDialog}
				images={previewImages}
			/>
		</Dialog>
	);
};
export default ArtworkTableDialog;
