import React from "react";

const ItemCountBadge = ({ howMany }) => {
	return (
		<div
			className="bg-info p-1 d-flex align-items-center justify-content-center"
			style={{
				color: "white",
				borderRadius: "100%",
				position: "absolute",
				fontSize: "8px",
				bottom: 0,
				right: -5,
				width: 15,
				height: 15,
			}}
		>
			{howMany}
		</div>
	);
};

export default ItemCountBadge;
